<template>
  <div class="pl-7 pt-6" v-if="agriculturalCrops.length > 0">
    <item-table-1 :records="agriculturalCrops" />
  </div>
  <div class="pl-7 pt-1" v-if="timberCrops.length > 0">
    <item-table-2 :records="timberCrops" />
  </div>
  <div class="pl-7 pt-1 mb-1" v-if="animals.length > 0">
    <item-table-3 :animals="animals" />
  </div>
</template>

<script>
import ItemTable1 from "./ItemTable1";
import ItemTable2 from "./ItemTable2";
import ItemTable3 from "./ItemTable3";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  components: {
    ItemTable1,
    ItemTable2,
    ItemTable3,
  },
  data() {
    return {
      records: [],
      animals: [],
      agriculturalCrops: [],
      timberCrops: [],
    };
  },
  methods: {
    loadData() {
      const me = this;
      const records1 = [];
      const records2 = [];
      me.item.attribute.forEach((el) => {
        if (el?.type_attributes_input?.id == 32) {
          me.animals.push({
            name: el?.name,
            value: el?.value_attributes[0].value,
          });
        } else if (el?.type_attributes_input?.id == 34) {
          records1.push(el);
        } else if (el?.type_attributes_input?.id == 33) {
          records2.push(el);
        } else if (el.id_name == "PRODUCCION_MINERA") {
          me.productionMinera = {
            name: el.name,
            value: el.value_attributes[0].value,
          };
        }
      });

      records1.forEach((el) => {
        el.subattributes.sort((a, b) => {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 0;
        });
      });

      records2.forEach((el) => {
        el.subattributes.sort((a, b) => {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 0;
        });
      });

      records1.forEach((el) => {
        el.subattributes.forEach((y) => {
          if (!y.subattribute_id) {
            me.agriculturalCrops.push({
              attribute_id: y.attribute_id,
              id: y.id,
              name: y?.id == 124 ? y?.value_attributes[0]?.value : y.name,
              subattributes: [],
            });
          }
          const record = me.agriculturalCrops.find(
            (z) => z.id == y.subattribute_id
          );

          if (record) {
            if (y.value_attributes.length > 0) {
              record.subattributes.push({
                id: y.id,
                name: y.name,
                value: y?.value_attributes[0].value,
                org_control_name: y.org_control_name,
                org_id: y?.org_id,
              });
            }
          }
        });
      });

      records2.forEach((el) => {
        el.subattributes.forEach((y) => {
          if (!y.subattribute_id) {
            me.timberCrops.push({
              attribute_id: y.attribute_id,
              id: y.id,
              name: y?.id == 156 ? y?.value_attributes[0]?.value : y.name,
              subattributes: [],
            });
          }
          const record = me.timberCrops.find((z) => z.id == y.subattribute_id);

          if (record) {
            if (y.value_attributes.length > 0) {
              record.subattributes.push({
                id: y.id,
                name: y.name,
                value: y.value_attributes[0].value,
                org_control_name: y.org_control_name,
                org_id: y?.org_id,
              });
            }
          }
        });
      });

      me.agriculturalCrops = me.agriculturalCrops.filter((el) => {
        if (el.subattributes.length > 0) return el;
      });

      me.agriculturalCrops.forEach((el) => {
        el.subattributes.sort((a, b) => {
          if (a.org_id > b.org_id) {
            return 1;
          }
          if (a.org_id < b.org_id) {
            return -1;
          }
          return 0;
        });
      });

      me.timberCrops = me.timberCrops.filter((el) => {
        if (el.subattributes.length > 0) return el;
      });

      me.timberCrops.forEach((el) => {
        el.subattributes.sort((a, b) => {
          if (a.org_id > b.org_id) {
            return 1;
          }
          if (a.org_id < b.org_id) {
            return -1;
          }
          return 0;
        });
      });

      me.timberCrops.sort((a, b) => a.name.localeCompare(b.name));
      me.agriculturalCrops.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  mounted() {
    const me = this;
    me.loadData();
  },
};
</script>
